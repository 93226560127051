<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular">{{ tableName }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top v-if="selectedRowIndex !== null && !Array.isArray(selectedRowIndex) && !objectData[selectedRowIndex].subtotal && !readOnly">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="openChartAccountInputDialog()"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar lançamentos</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="renew()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-file-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Renovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRenewGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRenewGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="editedRows = [], renew()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="renew(save = true)" :loading="saving">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="refresh()"
                            :disabled="disableActionButtons || hasEmptyRequiredFilter"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="editedRows = [], refresh()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(save = true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="saveData()"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'secondary' : 'primary'"
                            :disabled="disableActionButtons || readOnly || !unsavedChanges"
                            :loading="saving"
                        >
                            <v-icon>{{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="disableActionButtons || hasMultipleSelection || hasEmptyRequiredFilter"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApproval = true"
                            :disabled="disableActionButtons || hasMultipleSelection"
                        >
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title v-if="actualLevel !== null">
                                <span v-if="actualLevel.id_level > 0 && actualLevel.id_status !== 4"> Aprovar nível - {{ actualLevel.id_level }} </span>
                                <span v-else-if="actualLevel.id_status === 4"> Todos os níveis aprovados </span>
                                <span v-else> Nenhum nível cadastrado </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialogApproval = false, justification = ''"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedEvent"
                                        :items="options.find(option => option.column === 'id_event').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPurpose"
                                        :items="options.find(option => option.column === 'id_purpose').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Propósito"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedCostCenter"
                                        :items="options.find(option => option.column === 'id_cost_center').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Centro de custo"
                                        persistent-hint
                                    ></v-select>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn class="my-2 mr-2" color="error" @click="approvalSelect(0)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_level === 1 && actualLevel.id_status !== 4">
                                    Não aprovar
                                </v-btn>
                                <v-btn class="my-2" color="success" @click="approvalSelect(1)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_status === 4">
                                    Aprovar
                                </v-btn>
                                <v-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="showHistory === true ? approvals : levels"
                                        :loading="loading"
                                        item-key="id_dynamic_report"
                                        sort-by="Usuário"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="secondary" text @click="editedRows = [], closeGrid()" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="closeGrid(save = true)">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider class="mb-1"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center mb-1" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs  
                        v-model="tab"
                        height="24px"
                    >
                        <v-tabs-slider :color="'primary'"></v-tabs-slider>

                        <v-tab
                            v-for="item in tabItems"
                            :key="item"
                        >
                            <span class="text-capitalize">{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>

                <v-spacer></v-spacer>

                <v-col v-if="readOnly"
                    class="d-flex justify-end"
                    style="height: 24px !important; cursor: pointer !important;"
                >
                    <v-menu offset-y
                        style="z-index: 1000 !important;"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on"
                                class="d-flex justify-end align-start"
                            >
                                <div class="d-flex align-center">
                                    <v-icon small color="secondary" class="mr-2">mdi-alert-decagram</v-icon>
                                    <span class="secondary--text text-caption">Somente consulta</span>
                                </div>
                            </div>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-list-item dense
                                    v-for="(item, i) in readOnlyRules" :key="i"
                                    class="ma-0 pa-0"
                                >
                                    <v-list-item-icon class="mx-0 mr-2 pa-0">
                                        <v-icon color="secondary">mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-caption font-weight-regular">{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-row align="center" no-gutters class="pt-3">

                        <!-- EVENTO -->
                        <v-autocomplete
                            name="event"
                            :label="eventOption.label"
                            :items="eventOption.items"
                            item-value="text"
                            return-object
                            class="pa-0 mr-4"
                            v-model="event"
                            @change="selectEvent()"
                            @click="backupEvent = event"
                            :loading="loadingEvent"
                            style="max-width: 200px !important; z-index: 1000 !important;"
                            :disabled="unsavedChanges"
                        />

                        <v-dialog v-model="dialogEvent" max-width="850px">
                            <v-card>
                                <div class="d-flex justify-center">
                                    <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                                </div>
                                <v-card-actions class="px-6 pb-6">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="closeDialogEvent()" class="mr-4">Cancelar</v-btn>
                                    <v-btn color="secondary" text @click="editedRows = [], selectEvent()" class="mr-4">Continuar sem salvar</v-btn>
                                    <v-btn color="primary" @click="selectEvent(save = true)">Salvar e continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- PROPÓSITO -->
                        <div @click="setBackupPurpose()">
                            <v-autocomplete
                                v-model="purpose"
                                :items="loadingPurpose ? [] : purposeOption.items"
                                :label="purposeOption.label"
                                multiple
                                :loading="loadingPurpose"
                                class="pa-0 mr-4"
                                style="max-width: 200px !important; z-index: 1000 !important;"
                                return-object
                                @blur="confirmPurposeSelection()"
                                :no-data-text="loadingPurpose ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPurpose">
                                    <template v-if="loadingPurpose">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="purposeOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPurposes()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!purpose.length ? 'gray' : 'primary'">
                                                    {{ selectAllPurposeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="purpose.length === purposeOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="purpose.length !== purposeOption.items.length"
                                        style="display: inline-block; max-width: 150px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${purpose.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1month"
                                    @click="showAllMonths(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="260px">
                                        <!-- @change="setYear1Months($event)" -->
                                        <v-select
                                            v-model="year1months"
                                            :items="months"
                                            label="Escolha os meses"
                                            :item-text="item => item.text"
                                            @change="showMonth(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1month = true, showAllMonths(1)"
                                                :disabled="year1months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Tri"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1quarter"
                                    @click="showAllQuarters(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year1quarters"
                                            :items="quarters"
                                            label="Escolha os trimestres"
                                            :item-text="item => item.text"
                                            @change="showQuarter(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1quarters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1quarter = true, showAllQuarters(1)"
                                                :disabled="year1quarters.length === 4"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Sem"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1semester"
                                    @click="showAllSemesters(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year1semesters"
                                            :items="semesters"
                                            label="Escolha os semestres"
                                            :item-text="item => item.text"
                                            @change="showSemester(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1semesters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1semester = true, showAllSemesters(1)"
                                                :disabled="year1semesters.length === 2"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Ano 1"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="startingYear"
                                    @click="showYear(1)"
                                    :disabled="disableActionButtons"
                                />
                            </div>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2month"
                                    @click="showAllMonths(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2months"
                                            :items="months"
                                            :item-text="item => item.text"
                                            @change="showMonth(2)"
                                            label="Escolha os meses"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2month = true, showAllMonths(2)"
                                                :disabled="year2months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Tri"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2quarter"
                                    @click="showAllQuarters(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2quarters"
                                            :items="quarters"
                                            label="Escolha os trimestres"
                                            :item-text="item => item.text"
                                            @change="showQuarter(2)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2quarters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2quarter = true, showAllQuarters(2)"
                                                :disabled="year2quarters.length === 4"

                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Sem"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2semester"
                                    @click="showAllSemesters(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2semesters"
                                            :items="semesters"
                                            label="Escolha os semestres"
                                            :item-text="item => item.text"
                                            @change="showSemester(2)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2semesters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2semester = true, showAllSemesters(2)"
                                                :disabled="year2semesters.length === 2"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Ano 2"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="laterYear"
                                    @click="showYear(2)"
                                    :disabled="disableActionButtons"
                                />
                            </div>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex mt-1">
                            <v-checkbox
                                label="Subtotais"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="subtotals"
                                :disabled="disableActionButtons"
                            />
                        </div>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="pt-3">

                        <!-- CENTRO DE CUSTO -->
                        <v-col>
                            <v-autocomplete
                                v-model="costCenter"
                                :items="loadingCostCenter ? [] : costCenterOption.items"
                                :label="costCenterOption.label"
                                multiple
                                :loading="loadingCostCenter"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                :no-data-text="loadingCostCenter ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingCostCenter">
                                    <template v-if="loadingCostCenter">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="costCenterOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCostCenters()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!costCenter.length ? 'gray' : 'primary'">
                                                    {{ selectAllCostCenterIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="costCenter.length === costCenterOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="costCenter.length !== costCenterOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${costCenter.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA GERENCIAL -->
                        <v-col>
                            <v-autocomplete
                                v-model="accountGroup"
                                :items="loadingAccountGroup ? [] : accountGroupOption.items"
                                :label="accountGroupOption.label"
                                multiple
                                :loading="loadingAccountGroup"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getAccountGroups()"
                                :no-data-text="loadingAccountGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingAccountGroup">
                                    <template v-if="loadingAccountGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="accountGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllAccountGroups()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!accountGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllAccountGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="accountGroup.length === accountGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="accountGroup.length !== accountGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${accountGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA CONTABIL -->
                        <v-col>
                            <v-autocomplete
                                v-model="chartAccount"
                                :items="loadingChartAccount ? [] : chartAccountOption.items"
                                :label="chartAccountOption.label"
                                multiple
                                :loading="loadingChartAccount"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getChartAccounts()"
                                :no-data-text="loadingChartAccount ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingChartAccount">
                                    <template v-if="loadingChartAccount">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="chartAccountOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllChartAccounts()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!chartAccount.length ? 'gray' : 'primary'">
                                                    {{ selectAllChartAccountIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="chartAccount.length === chartAccountOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="chartAccount.length !== chartAccountOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${chartAccount.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- TIPO DE CONTRATO -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesChannel"
                                :items="loadingSalesChannel ? [] : salesChannelOption.items"
                                :label="salesChannelOption.label"
                                multiple
                                :loading="loadingSalesChannel"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesChannels()"
                                :no-data-text="loadingSalesChannel ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesChannel">
                                    <template v-if="loadingSalesChannel">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesChannelOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesChannels()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesChannel.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesChannelIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesChannel.length === salesChannelOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesChannel.length !== salesChannelOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesChannel.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- GERENTE EXECUTIVO -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesForce"
                                :items="loadingSalesForce ? [] : salesForceOption.items"
                                :label="salesForceOption.label"
                                multiple
                                :loading="loadingSalesForce"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesForces()"
                                :no-data-text="loadingSalesForce ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesForce">
                                    <template v-if="loadingSalesForce">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesForceOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesForces()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesForce.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesForceIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesForce.length === salesForceOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesForce.length !== salesForceOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesForce.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- BP -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesForceBP"
                                :items="loadingSalesForceBP ? [] : salesForceBPOption.items"
                                :label="salesForceBPOption.label"
                                multiple
                                :loading="loadingSalesForceBP"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesForceBPs()"
                                :no-data-text="loadingSalesForceBP ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesForceBP">
                                    <template v-if="loadingSalesForceBP">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesForceBPOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesForceBPs()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesForceBP.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesForceBPIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesForceBP.length === salesForceBPOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesForceBP.length !== salesForceBPOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesForceBP.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA CLIENTE -->
                        <v-col>
                            <v-autocomplete
                                v-model="customerGroup"
                                :items="loadingCustomerGroup ? [] : customerGroupOption.items"
                                :label="customerGroupOption.label"
                                multiple
                                :loading="loadingCustomerGroup"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getCustomerGroups()"
                                :no-data-text="loadingCustomerGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingCustomerGroup">
                                    <template v-if="loadingCustomerGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="customerGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCustomerGroups()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!customerGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllCustomerGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="customerGroup.length === customerGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="customerGroup.length !== customerGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${customerGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
                        <v-col class="d-flex justify-start" cols="2"
                            @click="handleSetAllHiddenColumns()"
                            style="cursor: pointer"
                        >
                            <v-icon class="mr-2" :color="setAllHiddenColumnsColor">{{ setAllHiddenColumnsIcon }}</v-icon>
                            <span class="text-subtitle-2">{{ setAllHiddenColumnsText }}</span>
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="purposeOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurpose"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="costCenterOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCostCenter"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <!-- <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Conta gerencial"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showAccountGroup"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col> -->

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Conta contábil"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showChartAccount"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesChannelOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesChannel"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesForceOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesForce"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesForceBPOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesForceBP"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="customerGroupOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCustomerGroup"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Justificativa da despesa *"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showOpexDescription"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="windowSize.y - 64 - 192" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                fixedColumnsLeft="2"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="50"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="{
                id_event: event ? event.id : null,
                id_purpose: purpose.length ? purpose[0].id : null,
                id_cost_center: costCenter.length ? costCenter[0].id_cost_center : null,
            }"
        />

        <v-dialog v-model="dialogSolver" max-width="900px"
            style="z-index: 5000 !important;"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    <span class="headline">Atingir meta</span>
                </v-card-title>
                <v-card-text>
                    <ZnapSolverDialog 
                        :solver="solverProps"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- fullscreen hide-overlay -->
        <v-dialog
            v-model="dialogChartAccountInput"
            persistent
            no-click-animation
            style="z-index: 1000 !important;"
        >
            <ZnapChartAccountInputDialog v-if="dialogChartAccountInput"
                :dialogChartAccountInput="dialogChartAccountInput"
                :clickedRow="clickedRow"
                :readOnly="readOnly"
                :event="event"
                :purpose="purpose"
                :purposeOption="purposeOption"
                :costCenter="costCenter"
                :costCenterOption="costCenterOption"
                :year1="year1"
                :year2="year2"
                :startingMonthIndex="startingMonthIndex"
                :fiscalYearStartMonth="fiscalYearStartMonth"
                :windowSize="windowSize"
                @closeChartAccountInputDialog="closeDialogChartAccountInput($event)"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import moment from 'moment'
import lodash from 'lodash'
import CommentsMenu from '@/components/znap/CommentsMenu.vue'
import ZnapSolverDialog from '@/components/znap/ZnapSolverDialog.vue'
import ZnapChartAccountInputDialog from '@/components/znap/ZnapChartAccountInputDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapSolverDialog, ZnapChartAccountInputDialog
    },

    props: {
        options: { type: Array, required: true },
        idPlanningFunction: { type: Number, required: true }
    },

    data() {
        return {
            tab: 0,
            tabItems: ['Evento', 'Filtros', 'Exibir'],

            // Hot Table
            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula
                },
                afterSelection: () => {
                    if (this.hotInstance) {
                        let cellCoords = this.hotInstance.getSelectedLast()

                        if (cellCoords) {
                            let firstRow = cellCoords[0]
                            let lastRow = cellCoords[2]

                            if (firstRow === lastRow) {
                                this.selectedRowIndex = firstRow
                            } else {
                                this.selectedRowIndex = [ firstRow, lastRow ]
                            }
                        } else {
                            this.selectedRowIndex = null
                        }
                    }
                },
                afterChange: (changes) => {
                    if (changes) {
                        changes.forEach(c => {
                            let row = c[0]
                            let col = c[1]
                            let newVal = c[2]
                            let oldVal = c[3]
                            let physicalRow = this.hotInstance.toPhysicalRow(row)

                            if (oldVal === '') {
                                oldVal = null
                                this.objectData[physicalRow][col] = oldVal
                            }
                            
                            if (newVal != oldVal) {
                                if (!this.editedRows.includes(physicalRow)) {
                                    this.editedRows.push(physicalRow)
                                }
                            }
                        })
                    }
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row, col, prop) => {
                    var cellProperties = {}

                    const stylizedRow = this.stylizedRows[row] || { index: -1 }

                    if (row === stylizedRow.index) {
                        if (stylizedRow.className) {
                            cellProperties.className = stylizedRow.className
                        }

                        if (!this.readOnlyColumns.includes(col)) {
                            cellProperties.readOnly = !stylizedRow.readOnly ? false : true
                        } else {
                            const totalColumns = [
                                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                            ]

                            if (totalColumns.includes(prop)) {
                                cellProperties.className += ` border-sides-1 font-bold`
                            } else {
                                cellProperties.className += ` read-only-column`
                            }
                        }

                        if (stylizedRow.percent) {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    if (this.readOnly) {
                        cellProperties.readOnly = this.readOnly
                    }

                    return cellProperties
                }
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: {},
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                {
                    name() {
                        return 'Editar lançamentos'
                    },
                    hidden() {
                        let hidden = false
                        let cellCoords = this.getSelectedLast()
                        let firstRow = cellCoords[0]
                        let lastRow = cellCoords[2]

                        if (firstRow !== lastRow || this.getDataAtRowProp(firstRow, 'subtotal')) {
                            hidden = true
                        }

                        return hidden
                    },
                    callback: () => {
                        if (this.readOnly) {
                            return this.$toast.error('Desmarque todos os campos da aba Exibir para habilitar a edição')
                        } else {
                            return this.openChartAccountInputDialog()
                        }
                    }
                }
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            hotInstance: null,
            selectedRowIndex: null,

            dialogSolver: false,
            solverProps: {
                columnName: null,
                column: null,
                row: null,
                rowProps: null,
                targetRow: null,
                targetValue: null,
                changeRow: null,
            },

            // ! DADOS DO LOAD
            payload: {
                id_event: null,
                id_purpose: null,
                id_cost_center: null,
                id_account_group_version: null
            },
            
            event: null,
            loadingEvent: true,
            dialogEvent: false,
            backupEvent: null,

            purpose: [],
            loadingPurpose: true,
            showPurpose: false,
            backupPurpose: null,

            costCenter: [],
            loadingCostCenter: true,
            showCostCenter: false,

            accountGroup: [],
            loadingAccountGroup: false,
            showAccountGroup: true,

            chartAccount: [],
            loadingChartAccount: false,
            showChartAccount: false,
            
            salesChannel: [],
            loadingSalesChannel: false,
            showSalesChannel: false,

            salesForce: [],
            loadingSalesForce: false,
            showSalesForce: false,

            salesForceBP: [],
            loadingSalesForceBP: false,
            showSalesForceBP: false,

            customerGroup: [],
            loadingCustomerGroup: false,
            showCustomerGroup: false,

            showOpexDescription: false,
            subtotals: true,

            disableEdition: false,
            readOnlyRules: [],

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            year1: null,
            year2: null,
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            readOnlyColumns: [],
            totalColumns: [],

            // Unsaved changes
            editedRows: [],
            dialogCloseGrid: false,
            dialogRefreshGrid: false,
            dialogRenewGrid: false,

            windowSize: { x: 0, y: 0 },
            loading: true,
            saving: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            drawer: false,
            exporting: false,
            importing: false,

            dialogChartAccountInput: false,
            clickedRow: null,
            
            // workflow
            readOnly: false,
            readOnlyApproval: false,
            dialogApproval: false,
            loadingApproval: false,
            justification: '',
            headers: [
                {
                    text: 'Semáforo', value: 'id_status'
                },
                {
                    text: 'Nível', value: 'approval_flow_level_description'
                },
                {
                    text: 'Status', value: 'status'
                },
                {
                    text: 'Usuário', value: 'name'
                },
                {
                    text: 'Data', value: 'date'
                },
                {
                    text: 'Justificativa', value: 'justification'
                }
            ],
            levels: [],
            selectedPurpose: null,
            costCenters: [],
            selectedCostCenter: null,
            selectedEvent: null,
            showHistory: false,
            approvals: null,
            userGroups: [],
            actualLevel: {
                id_level: 0,
                id_group: 0
            },
            disableApprove: true
        }
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
        this.addDoubleClickHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },
    
    destroyed() {
        this.removePreventCloseHandler()
        this.removeDoubleClickHandler()
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        disableActionButtons() {
            return this.loadingEvent
                || this.loadingPurpose
                || this.loadingCostCenter
        },

        unsavedChanges() {
            if (this.editedRows.length > 0) {
                return true
            } else {
                return false
            }
        },

        hasMultipleSelection() {
            return this.purpose.length > 1
                || this.salesChannel.length > 1
                || this.salesForce.length > 1
                || this.salesForceBP.length > 1
                || this.customerGroup.length > 1
                || this.costCenter.length > 1
        },

        hasMultipleColumns() {
            return this.showPurpose
                || this.showCostCenter
                || this.showChartAccount
                || this.showSalesChannel
                || this.showSalesForce
                || this.showSalesForceBP
                || this.showCustomerGroup
                || this.showOpexDescription
        },

        hasEmptyRequiredFilter() {
            return !this.event || !this.purpose.length || !this.costCenter.length
        },

        disableLoadGrid() {
            return false
        },

        setAllHiddenColumnsText() {
            return this.hasMultipleColumns ? 'DESMARCAR TODOS' : 'SELECIONAR TODOS'
        },

        setAllHiddenColumnsIcon() {
            if (!this.hasMultipleColumns) {
                return 'mdi-checkbox-blank-outline'
            } else {
                return 'mdi-close-box'
            }
        },

        setAllHiddenColumnsColor() {
            return this.hasMultipleColumns ? 'primary' : ''
        },

        eventOption() {
            return this.options.find(o => o.column === 'id_event')
        },

        purposeOption() {
            return this.options.find(o => o.column === 'id_purpose')
        },

        costCenterOption() {
            return this.options.find(o => o.column === 'id_cost_center')
        },

        accountGroupOption() {
            return this.options.find(o => o.column === 'id_account_group')
        },

        chartAccountOption() {
            return this.options.find(o => o.column === 'id_chart_account')
        },

        salesChannelOption() {
            return this.options.find(o => o.column === 'id_sales_channel')
        },

        salesForceOption() {
            return this.options.find(o => o.column === 'id_sales_force')
        },

        salesForceBPOption() {
            return this.options.find(o => o.column === 'id_sales_force_bp')
        },

        customerGroupOption() {
            return this.options.find(o => o.column === 'id_customer_group')
        },

        selectAllPurposeIcon() {
            let icon = ''

            if (!this.purpose.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.purpose.length < this.purposeOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllCostCenterIcon() {
            let icon = ''

            if (!this.costCenter.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.costCenter.length < this.costCenterOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllAccountGroupIcon() {
            let icon = ''

            if (!this.accountGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.accountGroup.length < this.accountGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllChartAccountIcon() {
            let icon = ''

            if (!this.chartAccount.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.chartAccount.length < this.chartAccountOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesChannelIcon() {
            let icon = ''

            if (!this.salesChannel.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesChannel.length < this.salesChannelOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesForceIcon() {
            let icon = ''

            if (!this.salesForce.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesForce.length < this.salesForceOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesForceBPIcon() {
            let icon = ''

            if (!this.salesForceBP.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesForceBP.length < this.salesForceBPOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllCustomerGroupIcon() {
            let icon = ''

            if (!this.customerGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.customerGroup.length < this.customerGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },
    },

    watch: {
        idPlanningFunction: {
            immediate: true,
            handler(newVal, oldVal) {
                if (!oldVal) {
                    return
                }

                if (newVal !== oldVal) {
                    this.renew()
                }
            }
        }
    },

    async beforeMount() {
        this.init()
    },

    methods: {
        async init() {
            this.listUserGroups()

            await this.getEvents()
            if (!this.eventOption.items.length) {
                return this.noEventsFound()
            }

            this.setDateColumns()

            await this.getPurposes()
            if (!this.purposeOption.items.length) {
                this.noPurposesFound()
            }

            await this.getCostCenters()
            if (!this.costCenterOption.items.length) {
                this.noCostCentersFound()
            }

            // await this.approvalListLevels()
            return this.loadGrid()
        },

        async getEvents() {
            this.loadingEvent = true

            let filter = {
                orderColumn: 'description',
                textColumn: 'description',
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_module",
                        operator: "=",
                        value: 10
                    },
                    {
                        AndOr: "AND",
                        column: "id_status",
                        operator: "NOT LIKE",
                        value: 2
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: this.idPlanningFunction
                    },
                ]
            }

            try {
                const eventRes = await this.$http.post(this.eventOption.endpoint[0] + this.eventOption.endpoint[1] + '/list-options', { filter })
                if (eventRes) {
                    this.eventOption.items = eventRes.data.rows

                    let foundFirst = this.eventOption.items.find((item, index) => index === 0)
                    if (foundFirst) {
                        this.event = foundFirst
                        this.year1 = this.event.fiscal_year
                        this.yearMonthInputStart = this.event.year_month_input_start
                        this.fiscalYearStartMonth = this.event.fiscal_year_start_month
                        this.startingMonthIndex = this.event.fiscal_year_start_month - 1
                        this.year2 = this.year1 + 1
                    }
                    
                    this.loadingEvent = false
                }
            } catch (err) {
                this.loadingEvent = false
                this.$fnError(err)
            }
        },

        closeDialogEvent() {
            this.event = this.backupEvent
            this.dialogEvent = false
        },

        async selectEvent(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogEvent = true  
            }

            this.loading = true
            this.dialogEvent = false

            this.year1 = this.event.fiscal_year
            this.yearMonthInputStart = this.event.year_month_input_start
            this.fiscalYearStartMonth = this.event.fiscal_year_start_month
            this.startingMonthIndex = this.event.fiscal_year_start_month - 1
            this.year2 = this.year1 + 1

            this.setDateColumns()

            this.purposeOption.items = []
            this.purpose = []

            this.costCenterOption.items = []
            this.costCenter = []

            this.loadingPurpose = true
            this.loadingCostCenter = true

            await this.getPurposes()
            if (!this.purposeOption.items.length) {
                this.noPurposesFound()
            }

            await this.getCostCenters()
            if (!this.costCenterOption.items.length) {
                this.noCostCentersFound()
            }

            // await this.approvalListLevels()
            return this.loadGrid()
        },

        noEventsFound() {
            this.event = null

            this.purpose = []
            this.loadingPurpose = false

            this.costCenter = []
            this.loadingCostCenter = false

            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
        },

        async getPurposes() {
            this.loadingPurpose = true

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose_type",
                        operator: "=",
                        value: 1 // Despesas
                    },
                    {
                        AndOr: "AND",
                        column: "matrix",
                        operator: "=",
                        value: 0
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_function",
                        operator: "=",
                        value: this.event.id_planning_function
                    },
                    {
                        AndOr: "AND",
                        column: "id_purpose_version",
                        operator: "=",
                        value: this.event.id_purpose_version
                    }
                ]
            }

            try {
                const purposeRes = await this.$http.post(this.purposeOption.endpoint[0] + this.purposeOption.endpoint[1] + '/list-options', { filter })
                if (purposeRes) {
                    this.purposeOption.items = purposeRes.data.rows

                    let foundFirst = this.purposeOption.items.find((item, index) => index === 0)
                    this.purpose.push(foundFirst)
                    this.loadingPurpose = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingPurpose = false
            }
        },

        setBackupPurpose() {
            this.backupPurpose = lodash.cloneDeep(this.purpose)
        },

        async confirmPurposeSelection() {
            if (lodash.isEqual(this.purpose, this.backupPurpose)) {
                return
            }

            this.costCenterOption.items = []
            this.costCenter = []
            this.loadingCostCenter = true

            await this.getCostCenters()
            if (!this.costCenterOption.items.length) {
                this.noCostCentersFound()
            }
        },

        setAllPurposes() {
            if (!this.purpose.length) {
                this.purpose = lodash.cloneDeep(this.purposeOption.items)
            } else {
                this.purpose = []
            }
        },

        noPurposesFound() {
            this.purpose = []
            this.costCenter = []

            this.noDataMessage = 'Cadastre um propósito para iniciar o planejamento'
            this.noDataBtn.path = '/purpose'
            this.noDataBtn.text = 'Cadastrar propósito'

            this.loading = false
        },

        async getCostCenters() {
            this.loadingCostCenter = true

            let filter = { conditions: [] }

            if (this.purpose.length && this.purpose.length <= this.purposeOption.items.length) {
                filter.conditions.push({
                    AndOr: "AND",
                    column: "id_purpose",
                    operator: "IN",
                    value: this.purpose.map(i => i.id)
                })
            }

            try {
                const costCenterRes = await this.$http.post(this.costCenterOption.endpoint[0] + this.costCenterOption.endpoint[1] + '/list-options', { filter })
                if (costCenterRes) {
                    this.costCenterOption.items = costCenterRes.data.rows

                    let foundFirst = this.costCenterOption.items.find((item, index) => index === 0)
                    this.costCenter.push(foundFirst)
                    this.loadingCostCenter = false
                }
            } catch (err) {
                this.loadingCostCenter = false
                this.$fnError(err)
            }
        },

        setAllCostCenters() {
            if (!this.costCenter.length) {
                this.costCenter = lodash.cloneDeep(this.costCenterOption.items)
            } else {
                this.costCenter = []
            }
        },

        noCostCentersFound() {
            this.costCenter = []
            this.loadingCostCenter = false
        },

        async getAccountGroups() {
            this.loadingAccountGroup = true

            let filter = { conditions: [] }

            if (this.purpose.length && this.purpose.length <= this.purposeOption.items.length) {
                filter.conditions.push({
                    AndOr: "AND",
                    column: "id_purpose",
                    operator: "IN",
                    value: this.purpose.map(i => i.id)
                })
            }

            try {
                const accountGroupRes = await this.$http.post(this.accountGroupOption.endpoint[0] + this.accountGroupOption.endpoint[1] + '/list-options', { filter })
                if (accountGroupRes) {
                    this.accountGroupOption.items = accountGroupRes.data.rows
                    this.loadingAccountGroup = false
                }
            } catch (err) {
                this.loadingAccountGroup = false
                this.$fnError(err)
            }
        },

        setAllAccountGroups() {
            if (!this.accountGroup.length) {
                this.accountGroup = lodash.cloneDeep(this.accountGroupOption.items)
            } else {
                this.accountGroup = []
            }
        },

        async getChartAccounts() {
            this.loadingChartAccount = true

            let filter = { conditions: [] }

            if (this.accountGroup.length && this.accountGroup.length <= this.accountGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: "AND",
                    column: "id_account_group",
                    operator: "IN",
                    value: this.accountGroup.map(i => i.id_account_group)
                })
            }

            try {
                const chartAccountRes = await this.$http.post(this.chartAccountOption.endpoint[0] + this.chartAccountOption.endpoint[1] + '/list-options', { filter })
                if (chartAccountRes) {
                    this.chartAccountOption.items = chartAccountRes.data.rows
                    this.loadingChartAccount = false
                }
            } catch (err) {
                this.loadingChartAccount = false
                this.$fnError(err)
            }
        },

        setAllChartAccounts() {
            if (!this.chartAccount.length) {
                this.chartAccount = lodash.cloneDeep(this.chartAccountOption.items)
            } else {
                this.chartAccount = []
            }
        },

        async getSalesChannels() {
            this.loadingSalesChannel = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",
                    column: "id_sales_channel_type",
                    operator: "=",
                    value: this.event.id_sales_channel_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesChannelRes = await this.$http.post(`${this.salesChannelOption.endpoint[0]}${this.salesChannelOption.endpoint[1]}/${this.salesChannelOption.endpoint[2] ? this.salesChannelOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesChannelRes) {
                    this.salesChannelOption.items = salesChannelRes.data.rows
                    this.loadingSalesChannel = false
                }
            } catch (err) {
                this.loadingSalesChannel = false
                this.$fnError(err)
            }
        },

        setAllSalesChannels() {
            if (!this.salesChannel.length) {
                this.salesChannel = lodash.cloneDeep(this.salesChannelOption.items)
            } else {
                this.salesChannel = []
            }
        },

        async getSalesForces() {
            this.loadingSalesForce = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_sales_force_type",
                    operator: "=",
                    value: this.event.id_sales_force_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            if (this.salesChannel.length && this.salesChannel.length <= this.salesChannelOption.items.length) {
                filter.conditions.push({
                    AndOr: "AND",
                    column: "id_sales_channel",
                    operator: "IN",
                    value: this.salesChannel.map(i => i.id)
                })
            }

            try {
                const salesForceRes = await this.$http.post(`${this.salesForceOption.endpoint[0]}${this.salesForceOption.endpoint[1]}/${this.salesForceOption.endpoint[2] ? this.salesForceOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesForceRes) {
                    this.salesForceOption.items = salesForceRes.data.rows
                    this.loadingSalesForce = false
                }
            } catch (err) {
                this.loadingSalesForce = false
                this.$fnError(err)
            }
        },

        setAllSalesForces() {
            if (!this.salesForce.length) {
                this.salesForce = lodash.cloneDeep(this.salesForceOption.items)
            } else {
                this.salesForce = []
            }
        },

        async getSalesForceBPs() {
            this.loadingSalesForceBP = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_sales_force_type",
                    operator: "=",
                    value: 7
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesForceBPRes = await this.$http.post(`${this.salesForceBPOption.endpoint[0]}${this.salesForceBPOption.endpoint[1]}/${this.salesForceBPOption.endpoint[2] ? this.salesForceBPOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesForceBPRes) {
                    this.salesForceBPOption.items = salesForceBPRes.data.rows
                    this.loadingSalesForceBP = false
                }
            } catch (err) {
                this.loadingSalesForceBP = false
                this.$fnError(err)
            }
        },

        setAllSalesForceBPs() {
            if (!this.salesForceBP.length) {
                this.salesForceBP = lodash.cloneDeep(this.salesForceBPOption.items)
            } else {
                this.salesForceBP = []
            }
        },

        async getCustomerGroups() {
            this.loadingCustomerGroup = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_customer_group_type",
                    operator: "=",
                    value: this.event.id_customer_group_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            if (this.salesChannel.length && this.salesChannel.length <= this.salesChannelOption.items.length) {
                filter.conditions.push({
                    AndOr: "AND",
                    column: "id_sales_channel",
                    operator: "IN",
                    value: this.salesChannel.map(i => i.id)
                })
            }

            if (this.salesForce.length && this.salesForce.length <= this.salesForceOption.items.length) {
                filter.conditions.push({
                    AndOr: "AND",
                    column: "id_sales_force",
                    operator: "IN",
                    value: this.salesForce.map(i => i.id)
                })
            }

            try {
                const customerGroupRes = await this.$http.post(`${this.customerGroupOption.endpoint[0]}${this.customerGroupOption.endpoint[1]}/${this.customerGroupOption.endpoint[2] ? this.customerGroupOption.endpoint[2] : 'list-options'}`, { filter })
                if (customerGroupRes) {
                    this.customerGroupOption.items = customerGroupRes.data.rows
                    this.loadingCustomerGroup = false
                }
            } catch (err) {
                this.loadingCustomerGroup = false
                this.$fnError(err)
            }
        },

        setAllCustomerGroups() {
            if (!this.customerGroup.length) {
                this.customerGroup = lodash.cloneDeep(this.customerGroupOption.items)
            } else {
                this.customerGroup = []
            }
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        closeDialogChartAccountInput(savedChanges) {
            this.clickedRow = {}
            this.dialogChartAccountInput = false

            if (savedChanges) {
                this.refresh()
            }
        },

        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
            }
        },

        addDoubleClickHandler() {
            window.addEventListener('dblclick', this.handleDoubleClick)
        },

        removeDoubleClickHandler() {
            window.removeEventListener('dblclick', this.handleDoubleClick)
        },

        handleDoubleClick() {
            if (!this.objectData[this.selectedRowIndex].subtotal) {
                return this.openChartAccountInputDialog()
            }
        },

        openChartAccountInputDialog() {
            if (this.readOnly) {
                return
            }

            if (this.showChartAccount === false) {
                this.dialogChartAccountInput = true
                this.clickedRow = this.objectData[this.selectedRowIndex]
            }
        },
        
        async loadGrid() {
            this.loading = true
            this.hotInstance = null
            this.editedRows = []
            this.selectedRowIndex = null

            this.noDataMessage = null
            this.noDataBtn = { path: null, text: null }

            if (this.disableLoadGrid) {
                return this.loading = false
            }

            if (this.purpose.length && this.costCenter.length) {
                this.selectedEvent = this.event.id
                this.selectedPurpose = this.purpose[0].id
                this.selectedCostCenter = this.costCenter[0].id
                this.levels = []
                this.approvals = []
                await this.approvalListLevels()
                await this.approvalHistory()
            }

            this.disableEdition = false
            this.readOnlyRules = []
            if (this.hasMultipleSelection || this.hasEmptyRequiredFilter) {
                this.disableEdition = true
            }
            
            this.readOnly = this.disableEdition || this.readOnlyApproval
            if (this.readOnly) this.setReadOnlyRules()

            let payload = this.setPayload()

            try {
                const opexPlanningRes = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/list', { ...payload })
                if (opexPlanningRes) {
                    let _colHeaders = []
                    let _columns = []
                    let resColumns = opexPlanningRes.data.columns
                    this.formulas = opexPlanningRes.data.formulas

                    opexPlanningRes.data.headers.forEach((header) => {
                        let headerIndex = parseInt(header.value.slice(5, 7), 10)
                        let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)
                        let headerText = header.text
                        if (columnIndex === -1) return

                        if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            if (header.value === 'value00') {
                                let values = header.text.split(' ')
                                headerText = `${values[0]} ${values[1] || ''}`
                            }

                            let year = null
                            if (headerIndex > 0 && headerIndex <= 12) {
                                if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                    year = this.year1
                                } else {
                                    year = this.year2
                                }
                                headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            } else if (headerIndex >= 13 && headerIndex <= 24) {
                                if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                    year = this.year2
                                } else {
                                    year = parseInt(this.year2, 10) + 1
                                }
                                headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            }
                        }
                        _colHeaders.push(headerText)

                        let readOnly = false
                        // Aqui modifica readOnly de acordo com id_status
                        this.readOnly ? readOnly = true: readOnly = false
                        // this.event.id_status === 3 ? readOnly = true : false
                        // this.event.id_status === 3 ? this.readOnly = true : false

                        if (header.editable === false) {
                            readOnly = true
                        }

                        let type = null
                        let className = ''
                        let numericFormat = null
                        if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        } else {
                            type = 'text'
                            className += ' htLeft'
                        }

                        let hide = false
                        if (header.hideColumn) {
                            hide = true
                        }

                        const totalColumns = [
                            'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                            'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                        ]

                        if (totalColumns.includes(header.value)) {
                            className += ' border-sides-1 font-bold'
                        }

                        _columns.push({ data: header.value, text: header.text, readOnly, type, className, numericFormat, hide, columnLetter: header.columnLetter })
                    })
                    this.colHeaders = _colHeaders
                    this.columns = _columns

                    this.setHiddenColumns()
                    this.setDateReadOnlyColumns()

                    let _rowHeaders = []
                    let _objectData = []
                    this.stylizedRows = []

                    opexPlanningRes.data.rows.forEach((row, index) => {
                        _rowHeaders.push('')

                        let rowProps = {
                            index,
                            row,
                            className: '',
                            format: row.format,
                            readOnly: false,
                            percent: false,
                        }

                        let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
                            return cur[1] && typeof cur[1] === 'string' && cur[1].includes('Subtotal -') ? acc + 1 : acc + 0
                        }, 0)

                        if (subtotalsQty > 1) {
                            let counter = 0
                            for (const field in row) {
                                if (row[field] && typeof row[field] === 'string' && row[field].includes('Subtotal -')) {
                                    if (counter > 0) {
                                        row[field] = ''
                                    }

                                    counter += 1
                                }
                            }
                        }

                        if (row.indent) {
                            rowProps.className += ' indentedRow'
                            row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                        }

                        if (row.format.includes('%')) {
                            rowProps.percent = true
                        }

                        if (row.data_type === 'INT' || row.data_type === 'DECIMAL') {
                            for (let i = 0; i <= 24; i++) {
                                if (i < 10) {
                                    row[`value0${i}`] = parseFloat(row[`value0${i}`], 10) || null
                                } else {
                                    row[`value${i}`] = parseFloat(row[`value${i}`], 10) || null
                                }
                            }
                        }

                        if (row.total > 0) {
                            // rowProps.className += ' formulaRow'

                            let foundFormula = this.formulas.find(formula => formula.system_id === row.system_id)
                            let startIndex = this.formulas.indexOf(foundFormula)
                            let endIndex = this.formulas.indexOf(foundFormula) + 23

                            if (foundFormula) {
                                for (let i = startIndex; i <= endIndex; i++) {
                                    row[this.formulas[i].column] = this.formulas[i].excelFormula
                                }
                            }
                        }

                        if (row.css.length) {
                            rowProps.className += ` ${row.css}`
                        }

                        if (row.subtotal) {
                            rowProps.className += ` font-bold subtotal`
                        }

                        if (this.showChartAccount === false) {
                            rowProps.readOnly = true
                        }

                        if (row.input_by_chart_account >= 1) {
                            rowProps.className += ` read-only-row`
                        }

                        if (row.editable != 1) {
                            if (row.input_by_chart_account >= 1 && this.showChartAccount === false) {
                                rowProps.readOnly = true
                            }
                        }

                        this.stylizedRows.push(rowProps)

                        _objectData.push(row)
                    })
                    this.rowHeaders = _rowHeaders
                    this.objectData = _objectData

                    if (!this.objectData.length) {
                        this.noDataMessage = 'Não há dados disponíveis'
                    }

                    this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        setPayload() {
            let filter = {
                conditions: []
            }

            if (this.purpose.length > 0 && this.purpose.length <= this.purposeOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_purpose',
                    operator: 'IN',
                    value: this.purpose.map(i => i.id)
                })
            }

            if (this.costCenter.length > 0 && this.costCenter.length <= this.costCenterOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_cost_center',
                    operator: 'IN',
                    value: this.costCenter.map(i => i.id_cost_center)
                })
            }

            if (this.accountGroup.length > 0 && this.accountGroup.length <= this.accountGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_account_group',
                    operator: 'IN',
                    value: this.accountGroup.map(i => i.id_account_group)
                })
            }

            if (this.chartAccount.length > 0 && this.chartAccount.length <= this.chartAccountOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_chart_account',
                    operator: 'IN',
                    value: this.chartAccount.map(i => i.id_chart_account)
                })
            }

            if (this.salesChannel.length > 0 && this.salesChannel.length <= this.salesChannelOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_channel',
                    operator: 'IN',
                    value: this.salesChannel.map(i => i.id)
                })
            }

            if (this.salesForce.length > 0 && this.salesForce.length <= this.salesForceOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_force',
                    operator: 'IN',
                    value: this.salesForce.map(i => i.id)
                })
            }

            if (this.salesForceBP.length > 0 && this.salesForceBP.length <= this.salesForceBPOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_force',
                    operator: 'IN',
                    value: this.salesForceBP.map(i => i.id)
                })
            }

            if (this.customerGroup.length > 0 && this.customerGroup.length <= this.customerGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_customer_group',
                    operator: 'IN',
                    value: this.customerGroup.map(i => i.id)
                })
            }

            let payload = {
                id_event: this.event.id,
                id_account_group_version: this.event.id_account_group_version,
                showPurpose: this.showPurpose,
                showCostCenter: this.showCostCenter,
                showChartAccount: this.showChartAccount,
                showSalesChannel: this.showSalesChannel,
                showSalesForce: this.showSalesForce,
                showSalesForceBp: this.showSalesForceBP,
                showCustomerGroup: this.showCustomerGroup,
                showAccountGroup: this.showAccountGroup,
                showOpexDescription: this.showOpexDescription,
                subtotals: this.subtotals,
                filter,
            }

            return payload
        },

        solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0){
            function ruleOf3 (initValChange, currTargetVal, initValTarget){
                if(initValTarget === 0 ) return 0
                return ( initValChange * currTargetVal ) / initValTarget
            }
            // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
            // OK! isso aí, Luc.
            const table = this.hotInstance

            let initialValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
            let initialValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

            let calculatedChangeRow = ruleOf3( initialValueFromChangeRow , targetValue , initialValueFromTargetRow )
            if(!calculatedChangeRow) return { interations:0, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }

            table.setDataAtCell(changeRow, column, calculatedChangeRow)

            // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

            let condition = true
            let interations = 0

            while (condition) {
                interations++
                let newValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
                let newValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

                let dif = targetValue - newValueFromTargetRow

                if( Math.abs(dif) <= 0.5 ){
                    condition = false
                    table.setDataAtCell(changeRow, column, initialValueFromChangeRow)
                    return { interations, newValueFromChangeRow, newValueFromTargetRow }
                }
                // TODO: testar o algoritimo e ajustar os incrementos e decrementos
                if( dif > 0 && dif <= 1 ) newValueFromChangeRow = newValueFromChangeRow + 0.1
                if( dif > 1 ) newValueFromChangeRow = newValueFromChangeRow + 1
                if( dif < 0 && dif >= -1 ) newValueFromChangeRow = newValueFromChangeRow - 0.1
                if( dif < -1 ) newValueFromChangeRow = newValueFromChangeRow - 1

                table.setDataAtCell(changeRow, column, newValueFromChangeRow)
                // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

                if(interations > 10000) condition = false
            }
            return { interations, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month =  true
            this.year1quarter =  true
            this.year1semester =  true
            this.laterYear =  true
            this.year2month =  true
            this.year2quarter =  true
            this.year2semester =  true

            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            this.year2months = this.months
            this.year1quarters = this.quarters
            this.year2quarters = this.quarters
            this.year1semesters = this.semesters
            this.year2semesters = this.semesters
        },

        handleSetAllHiddenColumns() {
            if (this.hasMultipleColumns) {
                this.showPurpose = false
                this.showCostCenter = false
                this.showChartAccount = false
                this.showSalesChannel = false
                this.showSalesForce = false
                this.showSalesForceBP = false
                this.showCustomerGroup = false
                this.showOpexDescription = false
            } else {
                this.showPurpose = true
                this.showCostCenter = true
                this.showChartAccount = true
                this.showSalesChannel = true
                this.showSalesForce = true
                this.showSalesForceBP = true
                this.showCustomerGroup = true
                this.showOpexDescription = true
            }
        },

        setHiddenColumns() {
            let _hiddenColumns = { columns: [], copyPasteEnabled: true }

            this.columns.forEach((column, index) => {
                if (column.hide) {
                    _hiddenColumns.columns.push(index)
                }
            })

            this.hiddenColumns = _hiddenColumns

            let cols = []

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth) {
                    if (i < 10) {
                        col = this.columns.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columns.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth) {
                    col = this.columns.findIndex(c => c.data === `value${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            this.hiddenColumns.columns.push(...cols)
        },

        setDateReadOnlyColumns() {
            const monthInputStart = parseInt((this.yearMonthInputStart).toString().slice(4, 6))
            const difference = monthInputStart - this.fiscalYearStartMonth

            this.readOnlyColumns = []

            // verify if month index is before company fiscal year
            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    let col = null

                    if (i < 10) {
                        col = `value0${i}`
                    } else {
                        col = `value${i}`
                    }

                    let foundColumn = this.columns.find(c => c.data === col)
                    let foundColumnIndex = this.columns.findIndex(c => c.data === col)
                    if (foundColumn) {
                        foundColumn.readOnly = true
                        this.readOnlyColumns.push(foundColumnIndex)
                    }
                }
            }

            const totalColumns = [
                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
            ]

            // verify if column is readOnly
            this.columns.forEach((c, i) => {
                if (totalColumns.includes(c.data) && c.readOnly){
                    this.readOnlyColumns.push(i)
                }
            })
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.year1months.length ? this.year1month = true : this.year1month = false
            this.year2months.length ? this.year2month = true : this.year2month = false
            
            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1quarters.length ? this.year1quarter = true : this.year1quarter = false
            this.year2quarters.length ? this.year2quarter = true : this.year2quarter = false
            
            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1semesters.length ? this.year1semester = true : this.year1semester = false
            this.year2semesters.length ? this.year2semester = true : this.year2semester = false
            
            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        async saveData() {
            this.saving = true

            let payload = { items: [] }

            this.editedRows.forEach(r => {
                let row = lodash.cloneDeep(this.objectData[r])

                if (!this.objectData[r].input_by_chart_account || this.showChartAccount) {
                    payload.items.push(row)
                }
            })

            try {
                const res = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/mass-save', { ...payload })
                if (res) {
                    this.saving = false
                    this.$toast.success(res.data.msg)
                    this.editedRows = []
                    this.loadGrid()
                    return 'success'
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
                return 'error'
            }
        },

        async renew(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogRenewGrid = true
            }

            this.loading = true

            this.dialogRenewGrid = false
            this.hotInstance = null

            this.eventOption.items = []
            this.event = []

            this.purposeOption.items = []
            this.purpose = []

            this.costCenterOption.items = []
            this.costCenter = []

            this.accountGroupOption.items = []
            this.accountGroup = []

            this.chartAccountOption.items = []
            this.chartAccount = []

            this.salesChannelOption.items = []
            this.salesChannel = []

            this.salesForceOption.items = []
            this.salesForce = []

            this.salesForceBPOption.items = []
            this.salesForceBP = []

            this.customerGroupOption.items = []
            this.customerGroup = []

            this.showPurpose = false
            this.showCostCenter = false
            this.showChartAccount = false
            this.showSalesChannel = false
            this.showSalesForce = false
            this.showSalesForceBP = false
            this.showCustomerGroup = false
            this.showOpexDescription = false
            
            this.loadingEvent = true
            this.loadingPurpose = true
            this.loadingCostCenter = true

            return this.init()
        },

        async refresh(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogRefreshGrid = true
            }

            this.loading = true
            this.dialogRefreshGrid = false
            this.hotInstance = null
            
            return this.loadGrid()
        },

        async closeGrid(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogCloseGrid = true
            }
            
            return this.$router.push('/')
        },

        setReadOnlyRules() {
            if (this.readOnlyApproval) {
                if (this.actualLevel && this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4) {
                    this.readOnlyRules.push('Nível de aprovação não permite alterações')
                } else {
                    this.readOnlyRules.push('Evento com todos os níveis aprovados')
                }
            }
        },
        
        async exportSheet(){
            this.exporting = true

            let payload = this.setPayload()
            payload['columnsToRemove'] = this.setColumnsToRemove()

            try {
                const res = await this.$http.post(this.$ipOpexPlanning + 'opex-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        setColumnsToRemove() {
            let columnsToRemove = []

            // year 1 months
            for (let i = 1; i <= 12; i++) {
                let foundMonth = this.year1months.find(m => m.id === i)

                let index = i < 10 ? `0${i}` : i

                if (!foundMonth) {
                    columnsToRemove.push(`value${index}`)
                }
            }

            // year 2 months
            for (let i = 13; i <= 24; i++) {
                let foundMonth = this.year2months.find(m => m.id === i - 12)

                if (!foundMonth) {
                    columnsToRemove.push(`value${i}`)
                }
            }

            // year 1 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year1quarter${i}`)
                }
            }

            // year 2 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year2quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year2quarter${i}`)
                }
            }

            // year 1 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year1semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year1semester${i}`)
                }
            }

            // year 2 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year2semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year2semester${i}`)
                }
            }

            // years
            if (!this.startingYear) {
                columnsToRemove.push(`year1`)
            }

            if (!this.laterYear) {
                columnsToRemove.push(`year2`)
            }

            return columnsToRemove
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        // const massEffectRes = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/mass-effect', { ...uploadRes.data.id })
                        // if (massEffectRes) {
                            this.$toast.success(uploadRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh(false)
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        async approvalSelect (option) {
            const payload = {
                id_event: this.selectedEvent,
                id_purpose: this.selectedPurpose,
                id_cost_center: this.costCenter[0].id_cost_center,
                justification: this.justification
            }
            try {
                this.loadingApproval = true
                if (option === 1) {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/approve', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } else {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/disapprove', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
            this.dialogApproval = false
            this.loadingApproval = false
            this.justification = ''
            await this.approvalListLevels()
            this.approvalHistory()
            this.refresh()
        },

        async approvalListLevels () {
            let unapprovedLevels = []
            const payload = {
                id_event: this.selectedEvent,
                id_purpose: this.selectedPurpose ? this.selectedPurpose : null,
                id_cost_center: this.costCenter.length ? this.costCenter[0].id_cost_center : null
            }

            if (!payload.id_event || !payload.id_purpose || !payload.id_cost_center) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                        level.id_status === 5 || !level.id_status ? unapprovedLevels.push(level) : false
                    })
                    unapprovedLevels.length < 1 ? this.actualLevel = this.levels[this.levels.length - 1] : this.actualLevel = unapprovedLevels[0]
                    this.userGroups.forEach((group) => {
                        if (group.id_group === this.actualLevel.id_group) {
                            this.disableApprove = false
                        }
                    })
                    await this.getlevels()
                } else {
                    this.readOnlyApproval = false
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory () {
            const payload = {
                id_event: this.selectedEvent,
                id_purpose: this.selectedPurpose ? this.selectedPurpose : null,
                id_cost_center: this.costCenter.length ? this.costCenter[0].id_cost_center : null
            }

            if (!payload.id_event || !payload.id_purpose || !payload.id_cost_center) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((approval) => {
                        approval.date ? approval.date = moment(approval.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async listUserGroups () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_user",
                            operator: "=",
                            value: this.getUser.id_user
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipSecurity + 'user-group/list', { ...payload })
                if (res) {
                    this.userGroups = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getlevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_approval_flow_level",
                            operator: "=",
                            value: this.actualLevel.id_approval_flow_level
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow-level/list', { ...payload })
                if (res) {
                    if (res.data.rows.length > 0) {
                        res.data.rows[0].change_values === 1 ? this.actualLevel.readOnly = false : this.actualLevel.readOnly = true
                    }
                    if (this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4){
                        this.readOnlyApproval = this.actualLevel.readOnly
                        this.readOnlyApproval === true ? this.$toast.warning('Este nível de aprovação não permite alterações') : false
                    } else {
                        this.levels.length > 0 ? this.readOnlyApproval = true : this.readOnlyApproval = false
                        if (this.readOnlyApproval) {
                            this.$toast.success('Este evento está com todos os níveis aprovados')
                        }
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        }
    },
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}

.v-menu__content {
    max-width: 350px !important;
}
</style>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
